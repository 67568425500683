<template>
    <div>
        <div class="header">
            <span class="header_txt">直播回放</span>
        </div>
        <div class="header_box">
            <div class="left">
                <img src="@/assets/live.png" alt="">
                <span class="left_span">选择直播：</span>
                <Select v-model="storePrams.live_id" style="width:200px" @on-change="liveChange">
                    <Option v-for="item in liveList" :value="item.id" :key="item.id">{{ item.name }}</Option>
                </Select>
            </div>
            <div class="right">
                <!-- <button class="header_btn" @click="modify" style="width: 120px;margin-right: 10px;">修改顶图</button> -->
                <button class="header_btn" @click="link">完整链接</button>
            </div>
        </div>
        <div class="content">
            <Table :columns="liveTable" :data="dataTable" border>
                <template slot-scope="{ row }" slot="name">
                    <div>{{row.active.name ? row.active.name : ''}}</div>
                </template>
                <template slot-scope="{ row }" slot="path">
                    <div style="display:flex;align-items:center;height: 56px;justify-content:center;">
                        <img v-if="row.active.cover_id" :src="row.active.cover.path" alt="" style="width: 50px;">
                        <div v-else style="width: 56px;height: 50px;background-color: #f5f7fa;color:#c0c4cc;line-height:50px;text-align:center;">加载失败</div>
                    </div>
                </template>
                <template slot-scope="{ row }" slot="type">
                    <button class="header_btn" style="margin-right: 10px;" @click="replace(row)">替换</button>
                    <button class="header_btn" @click="projectLink(row)">项目链接</button>
                </template>
            </Table>
        </div>
        <div class="footer">
            <Page :total="total" show-elevator show-total @on-change="pageChange"/>
        </div>
        <!-- 修改顶图弹框 -->
        <!-- <Modal v-model="modifyModal" title="顶部图" width="50%">
            <div class="modifyModal_content">
                <span style="margin: 10px 20px 20px 20px;">顶部图片</span>
                <Upload
                        ref="upload"
                        :show-upload-list="false"
                        :format="['jpg', 'jpeg', 'png', 'gif']"
                        :max-size="2048"
                        :on-format-error="handleFormatError"
                        :on-success="imgProgress"
                        :on-progress="handleProgress"
                        multiple
                        action="//jsonplaceholder.typicode.com/posts/"
                        >
                    <div class="form_upload">
                        <Icon class="form_icon" type="md-add" />
                    </div>
                </Upload>
            </div>
            <div slot="footer">
                <Button @click="modifyModalF">取消</Button>
                <Button type="primary" @click="modifyModalT">确定</Button>
            </div>
        </Modal> -->
        <!-- 替换弹框 -->
        <Modal v-model="replaceModal" title="替换" width="50%">
            <div>
                <Form ref="replaceForm" :model="replaceForm" :label-width="120">
                    <FormItem label="视频封面" prop="people">
                        <Upload
                            ref="upload"
                            :show-upload-list="false"
                            :format="['jpg', 'jpeg', 'png', 'gif']"
                            :max-size="2048"
                            :on-format-error="handleFormatError"
                            :on-success="imgProgress"
                            :on-progress="handleProgress"
                            :headers="headers"
                            :action="uploadImg"
                            >
                            <div class="form_upload">
                                <div v-if="replaceForm.video_cover_id" class="mask_upload" style="margin: 0;">
                                    <img :src="imgPath" class="mask_img">
                                    <a href="#">
                                        <div class="mask">
                                            <Icon class="mask_icon" type="ios-trash-outline" @click.stop="imgDelete" />
                                        </div>
                                    </a>
                                </div>
                                <div v-else><Icon class="form_icon" type="md-add" /></div>
                            </div>
                        </Upload>
                    </FormItem>
                    <FormItem label="替换视频" prop="people">
                        <Upload
                            ref="upload"
                            :show-upload-list="false"
                            :format="['mp4', 'avi', 'mov', 'wmv','flv']"
                            :max-size="409600"
                            :on-exceeded-size="handleSizeError"
                            :on-format-error="handleFormatError"
                            :on-success="videoProgress"
                            :on-progress="handleProgress"
                            :headers="headers"
                            :action="uploadVideo"
                            >
                            <div class="form_upload">
                                <div v-if="replaceForm.video_id" class="mask_upload" style="margin: 0;">
                                    <video :src="videoPath" class="mask_img"></video>
                                    <a href="#">
                                        <div class="mask">
                                            <Icon class="mask_icon" type="ios-trash-outline" @click.stop="videoDelete" />
                                        </div>
                                    </a>
                                </div>
                                <div v-else><Icon class="form_icon" type="md-add" /></div>
                            </div>
                        </Upload>
                    </FormItem>
                    <FormItem label="回放主标题">
                        <Input type="text" v-model="replaceForm.main_title" style="width: 100%;"></Input>
                    </FormItem>
                    <FormItem label="回放副标题">
                        <Input type="text" v-model="replaceForm.sub_title" style="width: 100%;"></Input>
                    </FormItem>
                    <FormItem label="虚拟观看人数">
                        <Input type="text" v-model="replaceForm.virtual_number" style="width: 100%;"></Input>
                    </FormItem>
                </Form>
            </div>
            <div slot="footer">
                <Button @click="replaceModalF">取消</Button>
                <Button type="primary" @click="replaceModalT">确定</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import config from "@/config/index";
import { getCookies } from "@/utils/helper";
import { liveSelect, liveActive, edit_back_active, back_active_info } from '@/api/index'
    export default {
        data() {
            return {
                imgPath: '',
                videoPath: '',
                uploadImg: config.apiUrl + "/admin/other/uploads",
                uploadVideo: config.apiUrl + "/admin/other/uploads_video",
                headers: {"meiye-admin-api": getCookies("meiye-admin-api")},
                liveList: [],
                live_back_id: 0,
                live_active_id: '',
                //table
                liveTable: [
                    {title: '项目名称',slot: 'name',align:'center'},
                    {title: '项目图片',slot: 'path',align:'center'},
                    {title: '操作',slot: 'type',align:'center'}
                ],
                dataTable: [],
                //page
                total: 0,
                storePrams: {
                    page: 1,
                    limit: 30,
                },
                // modifyModal:false,
                replaceModal:false,
                replaceForm:{
                    video_cover_id: '',
                    video_id: '',
                    main_title: '',
                    sub_title: '',
                    virtual_number: ''
                },
                is_loading : false
            }
        },
        mounted(){
            this.live_back_list()
        },
        methods: {
            imgDelete(){
                this.replaceForm.video_cover_id = ''
                this.imgPath = ''
            },
            videoDelete(){
                this.replaceForm.video_id = ''
                this.videoPath = ''
            },
            liveChange(e){
                this.storePrams.live_id = e
                this.live_back_active()
            },
            live_back_active(){
                liveActive(this.storePrams).then(res => {
                    this.total = res.data.total
                    this.dataTable = res.data.data
                })
            },
            live_back_list(){
                liveSelect().then(res => {
                    this.liveList = res.data
                    if(this.liveList.length > 0) {
                        this.storePrams.live_id = this.liveList[0].id
                        this.live_back_active()
                    }
                })
            },
            // modify(){
            //     this.modifyModal = true    
            // },
            link(){
                this.$Message.success({
                    background: true,
                    content: '请前往app分享',
                    closable: true
                });                
            },
            pageChange(index) {
                this.storePrams.page = index;
                this.live_back_active()
            },
            // modifyModalF(){
            //     this.modifyModal = false
            // },
            //修改顶部图弹框确定按钮
            // modifyModalT(){
            //     this.modifyModal = false
            // },
            replace(e){
                this.live_active_id = e.id
                this.replaceForm = {
                    video_cover_id: '',
                    video_id: '',
                    main_title: '',
                    sub_title: '',
                    virtual_number: ''
                }
                this.replaceModal = true
                back_active_info({
                    id : this.live_active_id
                }).then(res => {
                    this.replaceForm = res.data
                    if(res.data.img){
                        this.imgPath = res.data.img.path
                    }
                    if(res.data.video){
                        this.videoPath = res.data.video.path
                    }
                })
            },
            replaceModalF(){
                this.replaceModal = false
            },
            replaceModalT(){
                let data = {
                    id : this.live_active_id,
                    video_cover_id: this.replaceForm.video_cover_id,
                    video_id: this.replaceForm.video_id,
                    main_title: this.replaceForm.main_title,
                    sub_title: this.replaceForm.sub_title,
                    virtual_number: this.replaceForm.virtual_number
                }
                edit_back_active(data).then(res => {
                    this.replaceModal = false
                    this.live_back_active()
                    this.$Message.success(res.msg);
                })
            },
            projectLink(e){
                this.$Message.success({
                    background: true,
                    content: '请前往app分享',
                    closable: true
                });  
            },
            //文件上传类型错误
            handleFormatError() {
                this.$Message.warning("暂不支持上传此格式");
            },
            handleSizeError(){
                this.$Message.warning("视频文件上传大小不能超过400M");
            },
            // 文件上传时
            handleProgress() {
                if (this.is_loading) {return}
                this.is_loading = true;
                const msg = this.$Message.loading({
                    content: "上传中...",
                    duration: 0,
                });
            },
            // 视频封面上传
            imgProgress(e) {
                this.replaceForm.video_cover_id = e.data.id
                this.imgPath = e.data.path
                this.$Message.destroy();
                this.$Message.success(e.msg);
                this.is_loading = false;
            },
            // 视频上传
            videoProgress(e) {
                this.replaceForm.video_id = e.data.id
                this.videoPath = e.data.path
                this.$Message.destroy();
                this.$Message.success(e.msg);
                this.is_loading = false;
            },
        },
    }
</script>

<style lang="scss" scoped>
.form_mask .form_upload:hover{
    border: 1px dashed #409eff;
}
.form_mask{
    display:flex;
    flex-wrap:wrap;
    width: 100%;
}
.mask_upload{
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 10px;
    margin: 0 10px 10px 0;
    position: relative;
}
.mask_img{
    width: 150px;
    height:150px;
    border-radius: 10px;
}
.mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 150px;
    height: 150px;
    background: rgba(101, 101, 101, 0.6);
    color: #ffffff;
    opacity: 0;
    border-radius: 10px;
}
.mask .mask_icon {
    font-size: 20px;
}
.mask_upload a:hover .mask {
    opacity: 1; 
    text-align: center;  
    height: 150px;
    line-height: 150px;        
}
.header{
    width: 100%;
    height: 55px;
    padding: 16px;
    background-color: #cee0f9;
    border-top-left-radius: 15px; 
    border-top-right-radius: 15px;
    display: flex;
    align-items: center;
}
.header_txt{
    font-size: 20px;
    font-weight: bold;
    color: #000;
}
.header_box{
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 10px;
}
.header_btn{
    width: 100px;
    padding: 10px;
    background-color: #409eff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.left{
    display: flex;
    align-items: center;
}
.content{
}
.footer{
    margin: 20px 0;
    text-align: center;
}
.left_span{
    height: 21px;
    color: #374a70;
    font-weight: 700;
    font-size: 16px;
    margin-right: 4px;
    margin-left: 2px;
}
::v-deep .ivu-select-selection{
    height: 40px;
}
::v-deep .ivu-select-selected-value{
    height: 40px !important;
    line-height: 40px !important;
}
::v-deep .ivu-select-placeholder{
    height: 40px !important;
    line-height: 40px !important;
}
.form_upload{
    width: 190px;
    height: 160px;
    border: 1px dashed #c0c0c0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 10px;
}
.form_upload:hover{
    border: 1px dashed #409eff;
}
.form_icon{
    font-size: 30px;
    color: #808080;
}
.modifyModal_content{
    display: flex;
    flex-direction: row;
}
</style>